import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { sellerAccountSelectYearAtom } from '@service/seller/containers/SellerAccountContent/accountContent.atom';
import SellerAccountDetailPage from '@service/seller/page/SellerAccountDetailPage';
import SellerAccountPage from '@service/seller/page/SellerAccountPage';
import { useShopOrderListForSellerByShopAccountHook } from '@shared/api/shopOrder/shopOrder.hook';
import dayjs from 'dayjs';
import { Route, Routes, useParams } from 'react-router-dom';

export const SellerAccountRouter: FC = function SellerAccountRouter() {
  const { shopId } = useParams();

  const selectYear = useRecoilValue(sellerAccountSelectYearAtom);
  const selectedYearDate = dayjs(selectYear).toDate();
  const selectedYear = selectedYearDate.getFullYear();

  useShopOrderListForSellerByShopAccountHook(`${shopId}`, selectedYear);

  return (
    <Routes>
      <Route path="" element={<SellerAccountPage />} />
      <Route path="detail" element={<SellerAccountDetailPage />} />
    </Routes>
  );
};
