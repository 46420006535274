import { atom } from 'recoil';

import { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import dayjs from 'dayjs';

export const agencyAdminAccountTeamNameFilterAtom = atom<CheckBoxFilter | null>({
  key: 'agencyAdminAccountTeamNameFilterAtom',
  default: {},
});

export const agencyAdminAccountSelectYearAtom = atom<dayjs.Dayjs | null>({
  key: 'agencyAdminAccountSelectYearAtom',
  default: null,
});
