import { FC, useMemo } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { ShopOrderAgencyAdmin } from '@shared/api/shopOrder/shopOrder.atom';
import CheckboxFilter from '@shared/components/CheckboxFilter';
import { useAuth } from '@shared/state/hooks/useAuth';
import { getRebateDate } from '@shared/utils/dateUtil';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import { AgencyAdminAccountDetailModalButton } from '../AgencyAdminAccountDetail/AgencyAdminAccountDetailModalButton';
import {
  agencyAdminAccountSelectYearAtom,
  agencyAdminAccountTeamNameFilterAtom,
} from './AgencyAdminAccount.atom';

//* 이번달 및 다음달 정산 받아야하는 내용 표시

interface Props {
  shopOrderList: ShopOrderAgencyAdmin[];
}

type AccountData = {
  id: string;
  month: number;
  rebateDate: Date;
  rebateCount: number;
  rebateAgencyPrice: number;
  rebateWorkerPrice: number;
  shopOrderList: ShopOrderAgencyAdmin[];
};

const columns: (bugoBrand?: BugoBrand) => TableColumnsType<AccountData> = (
  bugoBrand?: BugoBrand,
) => {
  if (bugoBrand?.bugoAgency?.shareRebateWithWorker) {
    return [
      {
        title: '정산기간',
        dataIndex: 'month',
        key: 'month',
        align: 'center',
        render: (month: number) => {
          const now = new Date();
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                now.getTime() > getRebateDate(month).getTime() ? 'theme-text-warn-03' : ''
              }`}
            >
              {month}월
            </p>
          );
        },
      },
      {
        title: '정산지급일',
        dataIndex: 'rebateDate',
        key: 'rebateDate',
        align: 'center',
        render: (rebateDate: Date) => {
          const now = new Date();
          if (rebateDate.getTime() < now.getTime()) {
            return (
              <p className="break-keep px-6 py-1 text-base font-medium theme-text-warn-03">
                {formatDate(rebateDate)} (미지급)
              </p>
            );
          } else {
            return (
              <p className="px-6 py-1 text-base font-medium">{formatDate(rebateDate)}</p>
            );
          }
        },
      },
      {
        title: '총 판매건수',
        dataIndex: 'rebateCount',
        key: 'rebateCount',
        align: 'center',
        render: (rebateCount: number, record) => {
          const now = new Date();
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                now.getTime() > getRebateDate(record.month).getTime()
                  ? 'theme-text-warn-03'
                  : ''
              }`}
            >
              {rebateCount.toLocaleString()}개
            </p>
          );
        },
      },
      {
        title: bugoBrand.bugoAgency.nickName + ' 정산금',
        dataIndex: 'rebateAgencyPrice',
        key: 'rebateAgencyPrice',
        align: 'center',
        render: (rebateAgencyPrice: number, record) => {
          const now = new Date();
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                now.getTime() > getRebateDate(record.month).getTime()
                  ? 'theme-text-warn-03'
                  : ''
              }`}
            >
              {rebateAgencyPrice.toLocaleString()}원
            </p>
          );
        },
      },
      {
        title: '지도사 정산금',
        dataIndex: 'rebateWorkerPrice',
        key: 'rebateWorkerPrice',
        align: 'center',
        render: (rebateWorkerPrice: number, record) => {
          const now = new Date();
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                now.getTime() > getRebateDate(record.month).getTime()
                  ? 'theme-text-warn-03'
                  : ''
              }`}
            >
              {rebateWorkerPrice.toLocaleString()}원
            </p>
          );
        },
      },
      {
        title: '합계',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          const now = new Date();
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                now.getTime() > getRebateDate(record.month).getTime()
                  ? 'theme-text-warn-03'
                  : ''
              }`}
            >
              {(record.rebateWorkerPrice + record.rebateAgencyPrice).toLocaleString()}원
            </p>
          );
        },
      },
      {
        title: '세부내역보기',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="center-box">
              <AgencyAdminAccountDetailModalButton shopOrderList={record.shopOrderList} />
            </div>
          );
        },
      },
    ];
  } else {
    return [
      {
        title: '정산기간',
        dataIndex: 'month',
        key: 'month',
        align: 'center',
        render: (month: number) => {
          const now = new Date();
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                now.getTime() > getRebateDate(month).getTime() ? 'theme-text-warn-03' : ''
              }`}
            >
              {month}월
            </p>
          );
        },
      },
      {
        title: '정산지급일',
        dataIndex: 'rebateDate',
        key: 'rebateDate',
        align: 'center',
        render: (rebateDate: Date) => {
          const now = new Date();
          if (rebateDate.getTime() < now.getTime()) {
            return (
              <p className="break-keep px-6 py-1 text-base font-medium theme-text-warn-03">
                {formatDate(rebateDate)} (미지급)
              </p>
            );
          } else {
            return (
              <p className="px-6 py-1 text-base font-medium">{formatDate(rebateDate)}</p>
            );
          }
        },
      },
      {
        title: '총 판매건수',
        dataIndex: 'rebateCount',
        key: 'rebateCount',
        align: 'center',
        render: (rebateCount: number, record) => {
          const now = new Date();
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                now.getTime() > getRebateDate(record.month).getTime()
                  ? 'theme-text-warn-03'
                  : ''
              }`}
            >
              {rebateCount.toLocaleString()}개
            </p>
          );
        },
      },
      {
        title: bugoBrand ? bugoBrand.bugoAgency?.nickName + ' 정산금' : '상조 정산금',
        dataIndex: 'rebateAgencyPrice',
        key: 'rebateAgencyPrice',
        align: 'center',
        render: (rebateAgencyPrice: number, record) => {
          const now = new Date();
          return (
            <p
              className={`px-6 py-1 text-base font-medium ${
                now.getTime() > getRebateDate(record.month).getTime()
                  ? 'theme-text-warn-03'
                  : ''
              }`}
            >
              {rebateAgencyPrice.toLocaleString()}원
            </p>
          );
        },
      },
      {
        title: '세부내역보기',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="center-box">
              <AgencyAdminAccountDetailModalButton shopOrderList={record.shopOrderList} />
            </div>
          );
        },
      },
    ];
  }
};

export const NeedAccountTable: FC<Props> = function NeedAccountTable({
  shopOrderList: needAccountOrderList,
}: Props) {
  const { userProfile } = useAuth();

  const selectYear = useRecoilValue(agencyAdminAccountSelectYearAtom);
  const selectedYearDate = dayjs(selectYear).toDate();
  const selectedYear = selectedYearDate.getFullYear();

  const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];
  const isPreed = bugoBrand?.bugoAgency?.nickName === '프리드라이프';

  // TeamName Filtering
  const [selectedTeamNames, setSelectTeamNames] = useRecoilState(
    agencyAdminAccountTeamNameFilterAtom,
  );

  // 정산 필요내역들

  const monthlyNeedAccountOrders: { [month: number]: ShopOrderAgencyAdmin[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyNeedAccountOrders[i] = [];
  }

  needAccountOrderList.map((order) => {
    if (isPreed) {
      if (
        order.fevent?.deceasedInfo.coffinOut.date &&
        order.fevent.deceasedInfo.coffinOut.date?.getFullYear() === selectedYear
      ) {
        const month = order.fevent.deceasedInfo.coffinOut.date.getMonth() + 1;
        monthlyNeedAccountOrders[month]?.push(order);
      }
    } else {
      if (
        order.paymentDetail.requestedAt &&
        order.paymentDetail.requestedAt.getFullYear() === selectedYear
      ) {
        const month = order.paymentDetail.requestedAt.getMonth() + 1;
        monthlyNeedAccountOrders[month]?.push(order);
      }
    }
  });

  const accountOrderList = _.map(monthlyNeedAccountOrders, (orderList, index) => {
    if (orderList.length > 0) {
      return {
        month: Number(index),
        orderList: orderList,
      };
    } else return null;
  }).filter((item) => {
    return item;
  });

  const filteredAccounOrderList = (
    accountOrderList as { month: number; orderList: ShopOrderAgencyAdmin[] }[]
  ).map((item) => {
    if (selectedTeamNames) {
      return {
        month: item.month,
        orderList: item.orderList.filter((order) => {
          return selectedTeamNames[
            order?.fevent?.user?.bugoAgencyWorkerDetail?.teamName ?? 'etc'
          ];
        }),
      };
    } else {
      return {
        month: item.month,
        orderList: item.orderList,
      };
    }
  });

  const tableData: AccountData[] = useMemo(() => {
    return _.map(filteredAccounOrderList, (orderObj, index) => {
      return {
        id: String(index),
        month: orderObj.month,
        rebateDate: getRebateDate(orderObj.month),
        rebateCount: orderObj.orderList?.length ?? 0,
        rebateAgencyPrice:
          orderObj.orderList?.reduce((accu, prev) => {
            return accu + prev.orderDetail.shopItemEmbed.priceRebateAgency;
          }, 0) ?? 0,
        rebateWorkerPrice:
          orderObj.orderList?.reduce((accu, prev) => {
            return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
          }, 0) ?? 0,
        shopOrderList: orderObj.orderList,
      };
    });
  }, [filteredAccounOrderList]);

  const presentMonthAccount = useMemo(() => {
    return (
      <Table
        className="w-full"
        columns={columns(bugoBrand)}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          hideOnSinglePage: true,
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
    );
  }, [bugoBrand, tableData]);

  return (
    <div className="space-y-4">
      {selectedTeamNames && (
        <div className="flex items-center space-x-4 border px-4 text-sm theme-bg-1 theme-border-1">
          <div className={`center-box self-stretch bg-opacity-40 px-2 font-bold`}>
            소속
          </div>
          <div className="flex-1 py-1 pr-2">
            <div className="flex flex-wrap gap-x-3">
              <CheckboxFilter
                filter={selectedTeamNames}
                setFilter={(checked) => setSelectTeamNames(checked)}
              />
            </div>
          </div>
        </div>
      )}
      <p className="pl-4 text-base font-medium">정산예정내역</p>
      {presentMonthAccount}
    </div>
  );
};
