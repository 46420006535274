import {
  shopOrderForStoreDataAtomFamily,
  shopOrderForStoreStatusAtomFamily,
  shopOrderListAgencyAdminDataAtom,
  shopOrderListAgencyAdminStatusAtom,
  shopOrderListForAgencyAdminDataAtom,
  shopOrderListForAgencyAdminStatusAtom,
  shopOrderListForSellerByShopAccountDataAtomFamily,
  shopOrderListForSellerByShopAccountStatusAtomFamily,
  shopOrderListForSellerByShopDataAtomFamily,
  shopOrderListForSellerByShopStatusAtomFamily,
  shopOrderListForWorkerDataAtom,
  shopOrderListForWorkerStatusAtom,
  shopOrderNotPaidListForSellerByShopDataAtomFamily,
  shopOrderNotPaidListForSellerByShopStatusAtomFamily,
  shopOrderWaitingListForSellerByShopDataAtomFamily,
  shopOrderWaitingListForSellerByShopStatusAtomFamily,
} from '@shared/api/shopOrder/shopOrder.atom';
import { StoreRole } from '@shared/api/shopOrder/shopOrder.interface';
import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { DeliveryState, LowerRole, PaymentState } from '@shared/types';
import _ from 'lodash';

import {
  shopOrderListForStoreDataAtom,
  shopOrderListForStoreStatusAtom,
} from './shopOrder.atom';

const shopOrderForStorePopulate = {
  populate: [
    {
      path: 'shop',
    },
    {
      path: 'orderDetail.shopItem',
      populate: [
        {
          path: 'shopItemInfo',
          populate: {
            path: 'image',
          },
        },
        {
          path: 'image',
        },
      ],
    },
    {
      path: 'deliveryDetail.deliveredImage',
    },
  ],
};

// admin, bugo-agency-admin, bugo-agency, store-customer, public(default)

const vaildRolesShopOrderForStore = [
  LowerRole.Admin,
  LowerRole.StoreCustomer,
  LowerRole.StoreGuest,
];

export const shopOrderListForStoreUrl = (storeRole: StoreRole) =>
  `/${storeRole}/shop-order/?${queryString(shopOrderForStorePopulate)}`;

export const useShopOrderListForStoreHook = (storeRole: StoreRole) => {
  const disabled = !_.includes(vaildRolesShopOrderForStore, storeRole);

  useRecoilQuery(
    shopOrderListForStoreStatusAtom,
    shopOrderListForStoreDataAtom,
    shopOrderListForStoreUrl(storeRole),
    fetcher,
    disabled,
  );
};

export const shopOrderForStoreUrl = (shopOrderId: string, storeRole: StoreRole) => {
  return `/${storeRole}/shop-order/${shopOrderId}?${queryString(
    shopOrderForStorePopulate,
  )}`;
};

export const useShopOrderForStoreHook = (
  shopOrderId: string,
  storeRole: StoreRole,
  disableState: boolean,
) => {
  const disabled = !(_.includes(vaildRolesShopOrderForStore, storeRole) || disableState);

  useRecoilQuery(
    shopOrderForStoreStatusAtomFamily(shopOrderId),
    shopOrderForStoreDataAtomFamily(shopOrderId),
    shopOrderForStoreUrl(shopOrderId, storeRole),
    fetcher,
    disabled,
  );
};

export const usePublicShopOrderForStoreHook = (
  shopOrderId: string,
  storeRole: StoreRole,
  disableState: boolean,
) => {
  const disabled = !(_.includes(vaildRolesShopOrderForStore, storeRole) || disableState);

  useRecoilQuery(
    shopOrderForStoreStatusAtomFamily(shopOrderId),
    shopOrderForStoreDataAtomFamily(shopOrderId),
    shopOrderForStoreUrl(shopOrderId, LowerRole.Public),
    fetcher,
    disabled,
  );
};

//*상조관리자가 볼 화환내역 페이지용
export const shopOrderListByBugoBrandUrl = (bugoRole: LowerRole) => {
  return `/${bugoRole}/shop-order/?${queryString({
    filter: {
      'paymentDetail.status': { $in: [PaymentState.PAID, PaymentState.CONFIRMED] },
    },
    populate: [
      {
        path: 'fevent',
        populate: {
          path: 'user',
        },
      },
      {
        path: 'funeralHomeInfo',
      },
    ],
    options: {
      sort: { createdAt: -1 },
    },
  })}`;
};

//*상조관리자가 볼 화환내역 페이지용
export const useShopOrderListByBugoBrandHook = () => {
  const { bugoRole } = useAuth();

  useRecoilQuery(
    shopOrderListForAgencyAdminStatusAtom,
    shopOrderListForAgencyAdminDataAtom,
    shopOrderListByBugoBrandUrl(bugoRole()),
    fetcher,
  );
};

//*상조관리자가 볼 화환내역 페이지용 (refactoring)
export const shopOrderListAgencyAdminHookUrl = (
  bugoRole: LowerRole,
  selectYear?: number,
) => {
  const now = new Date();
  const startofYear = new Date(now.getFullYear(), -1, -7);

  if (selectYear) {
    const startofYear = new Date(selectYear, 0, -7);
    const lastofYear = new Date(selectYear + 1, 1, 1);
    return `/${bugoRole}/shop-order/?${queryString({
      select: [
        '_id',
        'paymentDetail',
        'orderDetail',
        'createdAt',
        'fevent',
        'bugoDetail',
        'funeralHomeInfo',
        'deliveryDetail',
      ],
      filter: {
        'paymentDetail.status': { $in: [PaymentState.PAID, PaymentState.CONFIRMED] },
        createdAt: { $gte: startofYear, $lte: lastofYear },
      },
      populate: [
        {
          path: 'fevent',
          select: ['_id', 'user', 'deceasedInfo.name', 'deceasedInfo.coffinOut'],
          populate: {
            path: 'user',
            select: ['_id', 'info', 'bugoAgencyWorkerDetail'],
          },
        },
        { path: 'funeralHomeInfo', select: ['_id', 'name', 'address'] },
        { path: 'deliveryDetail.deliveredImage' },
      ],
      options: {
        sort: { createdAt: -1 },
      },
    })}`;
  } else {
    return `/${bugoRole}/shop-order/?${queryString({
      select: [
        '_id',
        'paymentDetail',
        'orderDetail',
        'createdAt',
        'fevent',
        'bugoDetail',
        'funeralHomeInfo',
        'deliveryDetail',
      ],
      filter: {
        'paymentDetail.status': { $in: [PaymentState.PAID, PaymentState.CONFIRMED] },
        createdAt: { $gte: startofYear },
      },
      populate: [
        {
          path: 'fevent',
          select: ['_id', 'user', 'deceasedInfo.name', 'deceasedInfo.coffinOut'],
          populate: {
            path: 'user',
            select: ['_id', 'info', 'bugoAgencyWorkerDetail'],
          },
        },
        { path: 'funeralHomeInfo', select: ['_id', 'name', 'address'] },
        { path: 'deliveryDetail.deliveredImage' },
      ],
      options: {
        sort: { createdAt: -1 },
      },
    })}`;
  }
};

//*상조관리자가 볼 화환내역 페이지용 (refactoring)
export const useShopOrderListAgencyAdminHookHook = (selectYear?: number) => {
  const { bugoRole } = useAuth();

  useRecoilQuery(
    shopOrderListAgencyAdminStatusAtom,
    shopOrderListAgencyAdminDataAtom,
    shopOrderListAgencyAdminHookUrl(bugoRole(), selectYear),
    fetcher,
  );
};

//* seller용 검색
export const searchShopOrderListForSellerByShopUrl = function (
  shopId: string,
  role: LowerRole,
  searchText: string,
) {
  return `${role}/shop-order/?${queryString({
    populate: [
      {
        path: 'user',
      },
      {
        path: 'bugo',
        populate: [
          {
            path: 'bugoBrand',
          },
          {
            path: 'fevent',
          },
        ],
      },
      {
        path: 'orderDetail.shopItem',
        populate: [
          {
            path: 'shopItemInfo',
            populate: {
              path: 'image',
            },
          },
          {
            path: 'image',
          },
        ],
      },
      {
        path: 'deliveryDetail',
        populate: {
          path: 'deliveredImage',
        },
      },
    ],
    filter: {
      'paymentDetail.status': {
        $in: [
          PaymentState.CANCELED,
          PaymentState.CONFIRMED,
          PaymentState.PAID,
          PaymentState.CANCEL_REQUESTED,
        ],
      },
      'paymentDetail.isAccounted': { $ne: true },
      $or: [
        { 'orderDetail.shopItemEmbed.name': { $regex: searchText } },
        {
          'deliveryDetail.receiverAddress': { $regex: searchText },
        },
        {
          'deliveryDetail.senderName': { $regex: searchText },
        },
        {
          'deliveryDetail.receiverName': { $regex: searchText },
        },
        {
          'bugoDetail.senderPhrase': { $regex: searchText },
        },
      ],
    },
    options: {
      sort: {
        'paymentDetail.purchasedAt': -1,
      },
    },
  })}`;
};

//* seller용 shopOrder 정산되지 않은 내역에 대함 일주일 전 업데이트까지만
export const shopOrderListForSellerByShopUrl = function (
  shopId: string,
  role: LowerRole,
) {
  const now = new Date();
  return `${role}/shop-order/?${queryString({
    populate: [
      {
        path: 'user',
      },
      {
        path: 'bugo',
        populate: [
          {
            path: 'bugoBrand',
          },
          {
            path: 'fevent',
          },
        ],
      },
      {
        path: 'orderDetail.shopItem',
        populate: [
          {
            path: 'shopItemInfo',
            populate: {
              path: 'image',
            },
          },
          {
            path: 'image',
          },
        ],
      },
      {
        path: 'deliveryDetail',
        populate: {
          path: 'deliveredImage',
        },
      },
    ],
    filter: {
      'paymentDetail.status': {
        $in: [
          PaymentState.CANCELED,
          PaymentState.CONFIRMED,
          PaymentState.PAID,
          PaymentState.CANCEL_REQUESTED,
        ],
      },
      'paymentDetail.isAccounted': { $ne: true },
      $or: [
        {
          updatedAt: {
            $gt: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
          },
        },
        {
          'deliveryDetail.status': { $ne: DeliveryState.DELIVERED },
        },
      ],
    },
    options: {
      sort: {
        'paymentDetail.purchasedAt': -1,
      },
    },
  })}`;
};

export const useShopOrderListForSellerByShopHook = function (shopId: string) {
  const { sellerRole } = useAuth();

  useRecoilQuery(
    shopOrderListForSellerByShopStatusAtomFamily(shopId),
    shopOrderListForSellerByShopDataAtomFamily(shopId),
    shopOrderListForSellerByShopUrl(shopId, sellerRole()),
    fetcher,
  );
};

export const shopOrderWaitingListForSellerByShopUrl = function (
  shopId: string,
  role: LowerRole,
) {
  return `${role}/shop-order/?${queryString({
    populate: [
      {
        path: 'user',
      },
      {
        path: 'bugo',
        populate: [
          {
            path: 'bugoBrand',
          },
          {
            path: 'fevent',
          },
        ],
      },
      {
        path: 'orderDetail.shopItem',
        populate: [
          {
            path: 'shopItemInfo',
            populate: {
              path: 'image',
            },
          },
          {
            path: 'image',
          },
        ],
      },
    ],
    filter: {
      'paymentDetail.status': {
        $in: [PaymentState.WAITING],
      },
      'paymentDetail.isAccounted': { $ne: true },
    },
    options: {
      sort: {
        createdAt: -1,
      },
    },
  })}`;
};

export const shopOrderNotPaidListForSellerByShopUrl = function (role: LowerRole) {
  const now = new Date();
  // wantNow를 현재 날짜의 자정으로 설정

  const threeHoursAgo = new Date(now);
  threeHoursAgo.setHours(now.getHours() - 6);
  threeHoursAgo.setMinutes(0);
  threeHoursAgo.setSeconds(0);
  threeHoursAgo.setMilliseconds(0);

  return `${role}/shop-order/?${queryString({
    populate: [
      {
        path: 'user',
      },
      {
        path: 'bugo',
        populate: [
          {
            path: 'bugoBrand',
          },
          {
            path: 'fevent',
          },
        ],
      },
      {
        path: 'orderDetail.shopItem',
      },
    ],
    filter: {
      'paymentDetail.status': PaymentState.NOT_PAID,
      'paymentDetail.vBank': { $exists: true, $ne: null },
      'paymentDetail.requestAt': { $gte: threeHoursAgo },
    },
    options: {
      sort: {
        createdAt: -1,
      },
    },
  })}`;
};

export const useShopOrderWaitingListForSellerByShopHook = function (shopId: string) {
  const { sellerRole } = useAuth();

  useRecoilQuery(
    shopOrderWaitingListForSellerByShopStatusAtomFamily(shopId),
    shopOrderWaitingListForSellerByShopDataAtomFamily(shopId),
    shopOrderWaitingListForSellerByShopUrl(shopId, sellerRole()),
    fetcher,
  );
};

export const useShopOrderNotPaidListForSellerByShopHook = function (shopId: string) {
  const { sellerRole } = useAuth();
  useRecoilQuery(
    shopOrderNotPaidListForSellerByShopStatusAtomFamily(shopId),
    shopOrderNotPaidListForSellerByShopDataAtomFamily(shopId),
    shopOrderNotPaidListForSellerByShopUrl(sellerRole()),
    fetcher,
  );
};

//* 정산관련 페이지에 들어갈 shopOrder, 정산유무 상관없이 다들고옴 단 최대한 populate 없도록
export const shopOrderListForSellerByShopAccountUrl = function (
  shopId: string,
  role: LowerRole,
  selectYear?: number,
) {
  const now = new Date();
  const startofYear = new Date(now.getFullYear(), 0, -7);

  if (selectYear) {
    const startofYear = new Date(selectYear, 0, -7);
    const lastofYear = new Date(selectYear + 1, 1, 1);
    return `${role}/shop-order/?${queryString({
      populate: [
        {
          path: 'bugo',
          populate: [
            {
              path: 'user',
            },
            {
              path: 'bugoBrand',
            },
          ],
        },
        {
          path: 'funeralHomeInfo',
        },
        {
          path: 'fevent',
          select: ['deceasedInfo.coffinOut'],
        },
      ],
      filter: {
        'paymentDetail.status': {
          $in: [PaymentState.CONFIRMED, PaymentState.PAID],
        },
        createdAt: { $gte: startofYear, $lte: lastofYear },
      },
      options: {
        sort: {
          'paymentDetail.purchasedAt': -1,
        },
      },
    })}`;
  } else {
    return `${role}/shop-order/?${queryString({
      populate: [
        {
          path: 'bugo',
          populate: [
            {
              path: 'user',
            },
            {
              path: 'bugoBrand',
            },
          ],
        },
        {
          path: 'funeralHomeInfo',
        },
        {
          path: 'fevent',
          select: ['deceasedInfo.coffinOut'],
        },
      ],
      filter: {
        'paymentDetail.status': {
          $in: [PaymentState.CONFIRMED, PaymentState.PAID],
        },
        createdAt: { $gte: startofYear },
      },
      options: {
        sort: {
          'paymentDetail.purchasedAt': -1,
        },
      },
    })}`;
  }
};

export const useShopOrderListForSellerByShopAccountHook = function (
  shopId: string,
  selectYear?: number,
) {
  const { sellerRole } = useAuth();

  useRecoilQuery(
    shopOrderListForSellerByShopAccountStatusAtomFamily(shopId),
    shopOrderListForSellerByShopAccountDataAtomFamily(shopId),
    shopOrderListForSellerByShopAccountUrl(shopId, sellerRole(), selectYear),
    fetcher,
  );
};

//* 상조팀장들이 본인이 접근가능한 shopOrder에 대한 hook
export const shopOrderListForWorkerUrl = function (role: LowerRole) {
  const now = new Date();
  const startofYear = new Date(now.getFullYear(), 0, 1);
  return `${role}/shop-order/?${queryString({
    populate: [
      {
        path: 'fevent',
      },
      {
        path: 'funeralHomeInfo',
      },
    ],
    filter: {
      'paymentDetail.status': {
        $in: [PaymentState.CONFIRMED, PaymentState.PAID],
      },
      createdAt: { $gte: startofYear },
    },
    options: {
      sort: {
        'paymentDetail.purchasedAt': -1,
      },
    },
  })}`;
};

export const useShopOrderListForWorkerHook = function () {
  const { bugoRole } = useAuth();

  useRecoilQuery(
    shopOrderListForWorkerStatusAtom,
    shopOrderListForWorkerDataAtom,
    shopOrderListForWorkerUrl(bugoRole()),
    fetcher,
  );
};
